<template>
  <div class="auth-wrapper auth-v2">
    <div
      class="auth-inner m-0"
      style="overflow-x: hidden; width: 100%;"
      :class="`${windowWidth < 768 ? 'd-flex flex-column' : ''}`"
    >
      <b-img
        v-if="windowWidth < 768"
        style="width: 100%; "
        :src="imgUrl"
        alt="Login V2"
        @error="val => handleErrorImg(val)"
      />
      <div
        ref="bannerFullDiv"
        class="d-flex-center w-100 flex-grow-1"
        :style="`${
          windowWidth < 768
            ? 'background: linear-gradient(180deg, #fff 0%, #9FC9AD 100%);' :
              !!imgUrl ? `background: url(${imgUrl}) no-repeat center / contain; background-size: 100% 100%; height: 100vh;`
              :`background: url(${bannerFull}) no-repeat center / contain; background-size: 100% 100%; height: 100vh;`
        }`"
      >
        <!-- SECTION Login-->
        <b-row
          class="d-flex-center w-100 justify-content-end"
          :class="`${windowWidth < 768 ? 'my-3' : ''}`"
        >
          <b-col
            cols="12"
            md="4"
            class="d-flex-center"
          >
            <!-- :style="`${windowWidth < 768 ? '' : 'transform: translateX(-10%);'}`" -->
            <b-card
              no-body
              class="mb-0 d-flex-center flex-column shadow-lg p-2"
              :style="`${windowWidth < 768 ? '' : ''}`"
              style="width: 320px;"
            >
              <b-card-body class="w-100 p-50">
                <!-- header -->
                <b-link class="d-flex-center mb-1">
                  <b-img
                    :src="appLogoFull"
                    height="70"
                  />
                </b-link>

                <b-card-title class="mb-1">
                  {{ $t('login.welcomeTo') }} {{ appName }}! 👋
                </b-card-title>
                <b-card-text class="mb-1 text-info">
                  {{ $t('login.signInText') }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="refFormObserver">
                  <b-form class="auth-login-form mt-1">
                    <!-- email -->
                    <!-- <b-form-group
                      label="Email"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="userEmail"
                          :state="errors.length > 0 ? false : null"
                          name="login-email"
                          placeholder="admin@demo.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group> -->

                    <!-- username -->
                    <b-form-group
                      :label="$t('login.Username')"
                      label-for="login-username"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('login.Username')"
                        vid="username"
                        rules="required|min:2"
                      >
                        <b-form-input
                          id="login-username"
                          v-model="username"
                          trim
                          autocomplete="username"
                          :state="errors.length > 0 ? false : null"
                          name="login-username"
                          :formatter="upperCaseFormatter"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group
                      :label="$t('login.Password')"
                      label-for="login-password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('login.Password')"
                        vid="password"
                        rules="required|min:6"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="login-password"
                            v-model="password"
                            autocomplete="current-password"
                            trim
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder=""
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- checkbox keepLoggedIn -->
                    <b-form-group>
                      <b-form-checkbox
                        id="remember-me"
                        v-model="keepLoggedIn"
                        button-variant="warning"
                        name="checkbox-1"
                      >
                        {{ $t('login.keepLogin') }}
                      </b-form-checkbox>
                    </b-form-group>

                    <!-- submit buttons -->
                    <b-button
                      type="submit"
                      variant="warning"
                      block
                      @click.prevent="login"
                    >
                      {{ $t('login.LogIn') }}
                    </b-button>
                  </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-1">
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <span class="text-danger">{{ $t('login.forgotPassword') }}</span>
                  </b-link>

                  <!-- <b-link
                    class="d-block"
                    :to="{ name: 'auth-register' }"
                  >
                    <span>Đăng ký</span>
                  </b-link> -->
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- !SECTION /Login-->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BImg,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  VBTooltip,
  BCard, BCardBody,
  BRow, BCol,
} from 'bootstrap-vue'
import { useWindowSize } from '@vueuse/core'
import Vue from 'vue'
import { computed, watch } from '@vue/composition-api'

import i18n from '@/libs/i18n'
import { getHomeRouteForLoggedInUser, getUserData } from '@/api/auth/utils'
import useJwt from '@/api/auth/jwt/useJwt'
import IAmMFA from '@/components/IAmMFA.vue'
import store from '@/store'
import { env } from '@/libs/env'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'
import { required, email, userName } from '@validations'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { toastError } = useToast()
    const { refFormObserver } = formValidation()
    const { appName: defaultName, appLogoImage, appLogoFull: defaultLogo } = $themeConfig.app
    const appName = computed(() => store.getters['app/getTitleSite'] || defaultName)
    const appLogoFull = computed(() => store.getters['app/getLogo'] || defaultLogo)
    const { width: windowWidth } = useWindowSize()
    localStorage.setItem('Locale', 'vi')
    if (localStorage?.length < 2) {
      store.dispatch('app/loadConfig', { hostname: window.location.host })
    }
    const isShowAlertMfaWhenLogin = computed(() => store.getters['globalConfig/getShowAlertMfaWhenLogin'])

    const userData = computed(() => getUserData())

    watch(userData, () => {
      if (userData.value?.userData && userData.value?.employeeData) {
        window.location.reload()
      } else if (!userData.value?.userData && !userData.value?.employeeData) {
        store.dispatch('userStore/resetStore')
      }
    }, { immediate: true })

    // xử lý khi banner mobile khi lỗi
    function handleErrorImg(val) {
      val.srcElement.onerror = null
      val.srcElement.src = this.bannerMobile
    }
    return {
      refFormObserver,
      upperCaseFormatter,
      appName,
      appLogoImage,
      appLogoFull,
      windowWidth,
      toastError,
      isShowAlertMfaWhenLogin,
      handleErrorImg,
    }
  },
  data() {
    return {
      keepLoggedIn: true,
      password: '',
      userEmail: '',
      username: '',

      // validation rules
      required,
      email,
      userName,

      // banner default backup: // prior 3
      bannerFull: require('@/assets/banner-promotion/web-full.png'),
      bannerMobile: require('@/assets/banner-promotion/web-mobile.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      const banner = store.getters['app/getBanner'] // prior 1
      if (banner) {
        return banner
      }
      //   if (store.state.appConfig.layout.skin === 'dark') {
      //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //     this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //     return this.sideImg
      //   }
      //   return this.sideImg

      return this.isMobileView // prior 2
        ? (env?.bannerMobile || this.bannerFull)
        : (env?.bannerFull || this.bannerMobile)
    },
  },
  mounted() {
    const img = new Image()
    img.src = this.imgUrl
    img.onerror = () => {
      this.$refs.bannerFullDiv.style.background = `url(${this.bannerFull}) no-repeat center / contain`
      this.$refs.bannerFullDiv.style.backgroundSize = '100% 100%'
      this.$refs.bannerFullDiv.style.height = '100vh'
    }
  },
  methods: {
    login() {
      const storage = this.keepLoggedIn ? localStorage : sessionStorage
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.$bvModal.show('modal-api-loading')
            useJwt
              .login({
                username: this.username.toLowerCase(),
                password: this.password,
              })
              .then(async res => {
                let resReturn = res
                const { requiredSteps, sessionId } = res.data
                if (requiredSteps.includes('MFA_TOKEN')) {
                  this.$bvModal.hide('modal-api-loading')
                  await Vue.swal({
                    title: this.$t('myAccount.mfa.title'),
                    html: '<div id="i-am-mfa"></div>',
                    didOpen: () => {
                      new Vue({
                        render: h => h(IAmMFA),
                        i18n,
                      }).$mount('#i-am-mfa')
                    },
                    preConfirm: async () => {
                      const otp = document.getElementById('swal-input-otp-mfa').value
                      if (!otp) {
                        Vue.swal.showValidationMessage(this.$t('myAccount.mfa.inputOtpMfa'))
                        return
                      }
                      try {
                        const resMfaLogin = await useJwt.mfaLogin({ sessionId, otp })
                        // eslint-disable-next-line consistent-return
                        return resMfaLogin
                      } catch (error) {
                        Vue.swal.showValidationMessage(this.$t('myAccount.mfa.InvalidOtpMfa'))
                      }
                    },
                    showLoaderOnConfirm: true,
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirmation'),
                    cancelButtonText: this.$t('close'),
                    buttonsStyling: true,
                    customClass: {
                      confirmButton: 'btn btn-gradient',
                      cancelButton: 'btn btn-danger mr-1',
                    },
                    allowOutsideClick: false,
                    reverseButtons: true,
                  })
                    .then(value => {
                      if (value?.isDismissed) {
                        resReturn = null
                      } else if (value?.isConfirmed) {
                        resReturn = value.value
                      }
                    })
                  this.$bvModal.show('modal-api-loading')
                }
                return resReturn
              })
              .then(async userRes => {
                if (!userRes) return
                try {
                  const userData = userRes.data.user
                  useJwt.setStorage(storage)
                  useJwt.setToken(userRes.data.accessToken)
                  // eslint-disable-next-line consistent-return
                  return { userRes, userData }
                } catch (error) {
                  useJwt.clearStorage()
                  throw new Error('messagesList.loginError')
                }
              })
              .then(async res => {
                if (!res) return
                const { userRes, userData } = res
                const {
                  accessToken, expiresIn, refreshToken, refreshTokenExpiresIn,
                } = userRes.data

                if (userData) {
                  useJwt.setStorage(storage)
                  useJwt.setToken(accessToken)
                  useJwt.setRefreshToken(refreshToken)
                  useJwt.setTokenExpiresIn(expiresIn)
                  useJwt.setRefreshTokenExpiresIn(refreshTokenExpiresIn)
                  storage.setItem('userData', JSON.stringify(userData))
                  const employeeData = await this.$store.dispatch('userStore/fetchMeData')
                  if (employeeData) {
                    storage.setItem('employeeData', JSON.stringify(employeeData))
                    res.employeeData = employeeData
                  }
                }
                // await Promise.all([
                //   this.$store.dispatch('userStore/fetchGrantedScopes'),
                //   this.$store.dispatch('userStore/fetchFunctionScopes'),
                // ])
                // this.$ability.update(userData.ability)
                // eslint-disable-next-line consistent-return
                return res
              })
              .then(res => {
                if (!res) return
                const { userData, employeeData } = res
                if (!userData?.enableMFALogin && this.isShowAlertMfaWhenLogin) {
                  Vue.swal({
                    title: 'Cảnh báo!',
                    text: 'Tài khoản của bạn chưa cài Xác thực 2 bước (MFA)',
                    icon: 'warning',
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Cài đặt MFA ngay',
                    cancelButtonText: this.$t('modal.close'),
                    customClass: {
                      confirmButton: 'btn btn-info',
                      cancelButton: 'btn btn-outline-secondary mr-1',
                    },
                    reverseButtons: true,
                    buttonsStyling: false,
                    allowOutsideClick: true,
                  })
                    .then(value => {
                      if (value?.isConfirmed) {
                        this.$router.push({ name: 'account-settings', hash: '#mfa' })
                      }
                    })
                }
                if (userData && employeeData) {
                  this.$router
                    .replace(getHomeRouteForLoggedInUser(employeeData, this.$route.query?.callbackUrl))
                    .then(() => {
                      // toast when login successful
                      this.$root.toastSuccess({
                        title: 'messagesList.loginSuccessful',
                        content: `${this.$t('messagesList.welcome')} ${userData.username.toUpperCase()}`,
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      // this.$refs.loginForm.setErrors(error.response.data.error)
                    })
                }
              })
              .catch(error => {
                console.error({ error })
                let msgText
                // Data login wrong
                if (error?.response?.data.name === 'UnauthorizedError') {
                  if (['INVALID_USERNAME', 'Invalid user credentials'].includes(error?.response?.data.message)) {
                    msgText = 'messagesList.loginInfoIncorrect'
                  }
                }
                if (msgText) {
                  this.$root.toastError({
                    title: 'messagesList.error',
                    content: msgText,
                  })
                }
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          } else {
            // validate toast message error
            const { errors } = this.$refs.refFormObserver
            Object.keys(errors).forEach(key => {
              errors[key].forEach(msg => {
                // this.$root.toastError({
                //   title: 'Error',
                //   content: msg,
                // })
                console.log({ msg })
              })
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
